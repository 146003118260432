import "../component/service.css";
import React, { Component } from "react";
import { Link } from 'react-router-dom';
export class Service extends Component {
  static propTypes = {};

  render() {
    return (
      <div>
        <section id="about" className="py-5">
          <div className="container">
            <div className="row">
              <div className="col-md-4 py-5 ">
                <img
                  src="../service.png"
                  alt="Company "
                  className="img-fluid"
                  style={{ 'height': '400px'}}
                />
              </div>

              <div className="col-md-8  col-sm-12 px-5">
                <div className="servicebg ">
                <h2 >Services</h2>
                </div>
               
                <p>We’v been providing our services with proud since 2023.</p>
                <p>
                  Information Technology service management are the activities,
                  that are performed by an organization to design, deliver
                  build,operate and control information.
                </p>

                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <h5 className="servicetext">
                      <b>Mobile App Development</b>
                    </h5>
                    <p>
                      <i
                        className="bi bi-check-square-fill me-3"
                        style={{'color': 'purple'}}
                      ></i>
                      Transform your ideas into a mobile application through
                      Loginrich.
                    </p>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <h5 className="servicetext">
                      <b>Website Development</b>
                    </h5>
                    <p>
                      <i
                        className="bi bi-check-square-fill me-3"
                        style={{'color': 'purple'}}></i>
                      Our Web designers help to metamorphose your brand into a
                      visual story.
                    </p>
                  </div>
                </div>
                {
                  /**end of col-md-8  first row*/
                }

                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <h5 className="servicetext">
                      <b>Software Development</b>
                    </h5>
                    <p>
                      <i
                        className="bi bi-check-square-fill me-3"
                        style={{"color":"purple"}}
                      ></i>
                      Software solutions for a better world. Making your virtual
                      world better.
                    </p>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <h5 className="servicetext">
                      <b>E-commerce Solutions</b>
                    </h5>
                    <p>
                      <i
                        className="bi bi-check-square-fill me-3"
                        style={{'color': 'purple'}}
                      ></i>
                      Our E-Commerce applications are highly competent to
                      deliver immediate needs of the market landscape and get
                      aligned to every process digitally.
                    </p>
                  </div>
                  <div className="text-center">
                    <Link to="/itservice">
                      <span
                        className="btn "
                        style={{
                          'background-color': '#800080',
                          'color': 'white'
                        }}
                      >
                        IT Services
                      </span>
                    </Link>
                  </div>
                </div>
                {
                  /**end of col-md-8  second row*/
                }
              </div>
              {
                /**end of col-md-8 */
              }
            </div>
            {/* end of row */}
          </div>
          {/* end of conatiner */}
        </section>

        {
          /**why choose us */
        }
        <section className="container">
          <h2 className="text-center aboutusbg2">Why Choose US?</h2>

          <div className="row row-cols-1 row-cols-md-2 g-4">
            <div className="col">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title servicetext2">
                    <b>Expertise That Matters</b>
                  </h5>
                  <p className="card-text">
                    Our team comprises highly skilled and certified
                    professionals who are not just technically proficient, but
                    also passionate about staying up-to-date with the latest
                    trends and advancements in the IT landscape. This ensures
                    that you receive solutions that are not just effective
                    today, but also adaptable for the challenges of tomorrow.
                  </p>
                </div>
              </div>
            </div>

            <div className="col">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title servicetext2">
                    <b>Innovation at Core</b>
                  </h5>
                  <p className="card-text">
                    Your satisfaction is at the heart of everything we do. We go
                    the extra mile to ensure that our solutions align with your
                    business goals, are user-friendly, and deliver tangible
                    results. Our dedicated support team is always ready to
                    address your concerns and provide assistance whenever you
                    need it.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {
            /**end of row */
          }

          <div className="row row-cols-1 row-cols-md-2 g-4 mt-3 ">
            <div className="col">
              <div className="card pb-4">
                <div className="card-body">
                  <h5 className="card-title servicetext2">
                    <b>Client-Centric Approach</b>
                  </h5>
                  <p className="card-text ">
                    This is a longer card with supporting text below as a
                    natural lead-in to additional content. This content is a
                    little bit longer.
                    <br />
                    We're equipped to scale our solutions to accommodate your
                    growth, ensuring that your technology infrastructure remains
                    a solid foundation for your success.
                  </p>
                </div>
              </div>
            </div>

            <div className="col">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title servicetext2">
                    <b>Data Security and Privacy</b>
                  </h5>
                  <p className="card-text">
                    We understand the critical importance of safeguarding your
                    data. Our comprehensive security measures and adherence to
                    industry best practices ensure that your sensitive
                    information is protected from threats.
                    <br />
                    We are using highly secure technology
                    (Java,Python,Android,Aws,Flutter,etc.) for devloping
                    software product.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {
            /**end of row */
          }

          <div className="border border-danger mt-2 mb-5 p-2">
            <p>
              "Make the smart choice for your business's IT needs. Join hands
              with Loginrich Technology & Management Pvt.Ltd. and experience a
              partnership that's built on trust, expertise, and a genuine
              commitment to your success. Contact us today to explore how we can
              empower your business through the power of technology"
            </p>
          </div>
        </section>
        {
          /**end of why choose us */
        }
      </div>
    );
  }
}

export default Service;
