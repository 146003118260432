import '../component/footer.css';
import { Link } from "react-router-dom";
import React, { Component } from 'react'

export class Footer extends Component {
  static propTypes = {}

  render() {
    return (
      <div>
          <div className="container-fluid my-5">
  

  {/* Footer */}
  <footer
          className="text-center text-lg-start text-white p-1"
         
          >
    
  
    {/* Section: Links  */}
    <section className="p-2"  style={{'background-color': '#800080'}}> 
      <div className="container text-center text-md-start mt-3">
        {/* Grid row */}
        <div className="row mt-3">
          {/* Grid column */}
          <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
            {/* Content */}
            <h6 className="text-uppercase fw-bold">Loginrich</h6>
            <hr
                className="mb-4 mt-0 d-inline-block mx-auto"
                style={{'width': '60px', 'background-color': '#f6f4fa', 'height': '2px'}}
                />
            <p>
             
            <div>
                                          <h5 className='text-warning'><i className="fas fa-home me-2 " ></i><span className="icon-ind me-2"></span>India <small><i>(Corporate Office)</i></small></h5>
                                          <p><a className="text-light"  href="#">Srno29,Office No.5,Sawant Park,Balaji Nagar,Pune Satara Road,Pune, Maharashtra, 411043, IN</a></p>
                                      </div>
                                      
  
                                      <div>
                                          <h5 className="text-warning"><i className="fas fa-certificate me-2 " ></i>Legal Registration</h5>
                      <ul className='text-md-start'>
                        <li><span>Name: Loginrich Technology & Management Private Limited</span></li>
                        <li><span>PAN: AAFCL4588A</span></li>
                        <li><span>CIN: U58201PN2023PTC221772</span></li>
                      </ul>
                                          
                                      </div>
            </p>
          </div>
          {/* Grid column */}
  {/* Grid column */}
  <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
            {/* Links */}
            <h6 className="text-uppercase fw-bold">Contact</h6>
            <hr
                className="mb-4 mt-0 d-inline-block mx-auto bg-light"
                style={{'width':'60px' ,'height': '2px'}}
                />
           
           
            <div >
                                          <h5 className="text-warning"><i className="fas fa-envelope me-2"></i>Email</h5>
                                          <p className="mb-0"><a className="text-light" href="mailto:info@loginrich.com">info@loginrich.com</a></p>
                                          <p className="mb-0"><a className="text-light" href="mailto:career@loginrich.com">career@loginrich.com</a></p>
                                          <p className="mb-0"><a className="text-light" href="mailto:support@loginrich.com">support@loginrich.com</a></p>
                                      </div>
          
            
          
           
            <div className='mt-3'>
                                          <h5 className="text-warning"><i className="fas fa-phone-volume me-2 "></i> <span>Call</span></h5>
                                          <p className="mb-0"><a className="text-light" href="tel:+919890089721">+91 98 9008 9721</a></p>
                                          <p className="mb-0"><a className="text-light" href="tel:+918390691173">+91 83 9069 1173</a></p>
                                          
                                      </div>
          </div>
          {/* Grid column */}
          <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
            {/* Links */}
            <h6 className="text-uppercase fw-bold">Services</h6>
            <hr
                className="mb-4 mt-0 d-inline-block mx-auto"
                style={{'width': '60px',' background-color': '#f5f5f8', 'height': '2px'}}
                />
            <p>
              <Link to="/itservice" className="text-white">Mobile App Development</Link>
            </p>
            <p>
              <Link to="/itservice" className="text-white">Web Development</Link>
            </p>
            <p>
              <Link to="/itservice" className="text-white">Software Development</Link>
            </p>
            <p>
              <Link to="/itservice" className="text-white">E-commerce Solutions</Link>
            </p>
          </div>
          {/* Grid column */}
  
          {/* Grid column */}
          <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
            {/* Links */}
            <h6 className="text-uppercase fw-bold">Support</h6>
            <hr
                className="mb-4 mt-0 d-inline-block mx-auto"
                style={{'width': '60px', 'background-color': '#f3eff3', 'height': '2px'}}
                />
            <p>
              <Link to="/disclaimer" className="text-white">Disclaimer</Link>
            </p>
            <p>
              <Link to="/privacy" className="text-white">Privacy Policy</Link>
            </p>
            <p>
              <Link to="/terms_condition" className="text-white">Term and Condition</Link>
            </p>
          
          </div>
          {/* Grid column */}
  
          
          {/* Grid column */}
        </div>
        {/* Grid row */}
      </div>
    </section>
    {/* Section: Links  */}
  
    {/* Copyright */}
   {/* Section: Social media */}
   <section
             className="d-flex justify-content-between p-4 cbgcolor"
             
             >
      {/* Left */}
      <div className="me-5  ">
        
      Copyright © 2023:
      <a className="text-white" href="https://www.loginrich.com/"> Loginrich Technology And Management Pvt. Ltd.</a>
    
      </div>
      {/* Left */}
  
      {/* Right */}
      <div>
        <a href="/" className="text-white me-4">
          <i className="fab fa-facebook-f"></i>
        </a>
       
        <a href="/" className="text-white me-4">
          <i className="fab fa-google"></i>
        </a>
        <a href="/" className="text-white me-4">
          <i className="fab fa-instagram"></i>
        </a>
        <a href="/" className="text-white me-4">
          <i className="fab fa-linkedin"></i>
        </a>
       
      </div>
      {/* Right */}
    </section>
    {/* Section: Social media */}
   
    {/* Copyright */}
   
  </footer>
  {/* Footer */}
  
  </div>
  
  
  
      </div>
    )
  }
}

export default Footer