import React from "react";
import ListGroup from 'react-bootstrap/ListGroup';
import ListGroupItem from 'react-bootstrap/ListGroupItem';
import { Link } from "react-router-dom";
const AdminMenu=()=>{
    return(
<ListGroup>
  <ListGroupItem >
   
    <Link tag="a" to="/admin" action> View Contact</Link>
  </ListGroupItem>
  <ListGroupItem>
  <Link to="/admin"> Admin</Link>
  </ListGroupItem>
  <ListGroupItem>
  <Link to="/admin"> Admin</Link>
  </ListGroupItem>
  <ListGroupItem>
  <Link to="/admin"> Admin</Link>
  </ListGroupItem>
  <ListGroupItem>
  <Link to="/admin"> Admin</Link>
  </ListGroupItem>
</ListGroup>
    )
}
export default AdminMenu;