
import React, { Component } from 'react'

export class AdminHeader extends Component {

  render() {
    return (
      <div className='text-center'>Welcome To Admin Header</div>
    )
  }
}

export default AdminHeader