
import React, { Component } from 'react'


import Service from './Service'

import AboutUsHome from './AboutUsHome'
import { ContactUsHome } from './ContactUsHome'

export class Home extends Component {
  static propTypes = {}

  render() {
    return (
      <div>
   
  

        {/* <section  style={{ 'margin-top': '100px',' margin-bottom':'50px'}}> */}
        <section>
      

<div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
  
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 5"></button>
  
  </div>
  <div className="carousel-inner text-center">
   <div className="carousel-item active" data-bs-interval="1400">
       
        <img src="../img/new/1.png" className="img-fluid cimg" alt="" /> </div>
      <div className="carousel-item" data-bs-interval="1400">
        <img src="../img/new/2.png" className="img-fluid img-thumbnail cimg" alt=""/>
      </div>
      <div className="carousel-item" data-bs-interval="1400">
        <img src="../img/new/3.png" className="img-fluid img-thumbnail cimg" alt=""/>
      </div>
      <div className="carousel-item" data-bs-interval="1400">
        <img src="../img/new/4.png" className="img-fluid img-thumbnail cimg" alt=""/>
      </div>
      <div className="carousel-item" data-bs-interval="1400">
        <img src="../img/new/5.png" className="img-fluid img-thumbnail cimg" alt=""/>
      </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>
   
</section>
{/*<img src={bullet} />
   */}
        <AboutUsHome/>
        <Service/>
        <ContactUsHome/>
      </div>
    )
  }
}

export default Home