import '../component/disclaimer.css';
import React, { Component } from "react";
/*
import PropTypes from "prop-types";
*/
export class Disclaimer extends Component {
  static propTypes = {};

  render() {
    return (
      <div>
        <div class="container-fluid">
          <div class="container mt100">
            <div class="row">
              <div class="col-md-12">
                <ul class="list-group ">
                  <li class="list-group-item active fs-6" aria-current="true">
                    The following terms and conditions will be deemed to have
                    been accepted by the User on usage of the website
                    "https://loginrich.com" You are requested to read them
                    carefully before you use the services of this site.
                  </li>

                  <li class="list-group-item">
                    <span class="list_img"></span> User shall refer to the user
                    who is browsing the site. The term loginrich shall refer to
                    loginrich Technology And Management Pvt Ltd . The term Site
                    refers to www.loginrich.com owned and monitored by loginrich.
                  </li>
                  <li class="list-group-item ">
                    <span class="list_img"></span> By using the Site, you agree
                    to follow and be bound by the following terms and conditions
                    concerning your use of the Site. loginrich may revise the
                    Terms of Use at any time without notice to you. Areas of the
                    Site may have different terms of use posted. If there is a
                    conflict between the Terms of Use and terms of use posted
                    for a specific area of the Site, the latter shall have
                    precedence with respect to your use of that area of the
                    Site.
                  </li>
                  <li class="list-group-item ">
                    <span class="list_img"></span> loginrich may terminate User’s
                    access at any time for any reason. The provisions regarding
                    to disclaimer of warranty, accuracy of information, and
                    indemnification shall survive such termination. loginrich may
                    monitor access to the Site.
                  </li>
                  <li class="list-group-item">
                    <span class="list_img"></span> All content present on this
                    site is the exclusive property of loginrich. The software,
                    text, images, graphics, video and audio used on this site
                    belong to loginrich. No material from this site may be copied,
                    modified, reproduced, republished, uploaded, transmitted,
                    posted or distributed in any form without prior written
                    permission from loginrich. All rights not expressly granted
                    herein are reserved. Unauthorized use of the materials
                    appearing on this site may violate copyright, trademark and
                    other applicable laws, and could result in criminal or civil
                    penalties. loginrich is a registered trademark of loginrich
                    Technology And Management Pvt Ltd . This trademark may not be
                    used in any manner without prior written consent from
                    loginrich Technology And Management Pvt Ltd
                  </li>
                  <li class="list-group-item">
                    <span class="list_img"></span> loginrich does not make any
                    warranties, express or implied, including, without
                    limitation, those of merchantability and fitness for a
                    particular purpose, with respect to any information, data,
                    statements or products made available on the Site.
                  </li>
                  <li class="list-group-item">
                    <span class="list_img"></span> The Site, and all content,
                    materials, information, software, products and services
                    provided on the Site, are provided on an “as is” and “as
                    available” basis. loginrich expressly disclaims all warranties
                    of any kind, whether express or implied, including, but not
                    limited to, the implied warranties of merchantability,
                    fitness for a particular purpose and non-infringement.
                  </li>
                  <li class="list-group-item">
                    <span class="list_img"></span> loginrich shall have no
                    responsibility for any damage to User’s computer system or
                    loss of data that results from the download of any content,
                    materials, information from the Site.
                  </li>
                  <li class="list-group-item">
                    <span class="list_img"></span> loginrich may change or
                    discontinue any aspect of its website at any time,
                    including, its content or features. loginrich reserves the
                    right to change the terms and conditions applicable to use
                    of the Site. Such changes shall be effective immediately
                    upon notice, which shall be placed on the Site.
                  </li>
                  <li class="list-group-item">
                    <span class="list_img"></span> In no event will loginrich be
                    liable for damages of any kind, including without
                    limitation, direct, incidental or consequential damages
                    (including, but not limited to, damages for lost profits,
                    business interruption and loss of programs or information)
                    arising out of the use of or inability to use loginrich’s
                    website, or any information provided on the website, or in
                    the Products any claim attributable to errors, omissions or
                    other inaccuracies in the Product or interpretations
                    thereof. Some jurisdictions do not allow the limitation or
                    exclusion of liability. Accordingly, some of the above
                    limitations may not apply to the User.
                  </li>
                  <li class="list-group-item">
                    <span class="list_img"></span> User agrees to indemnify,
                    defend and hold loginrich harmless from and against all
                    losses, expenses, damages and costs, including reasonable
                    attorneys’ fees, arising out of or relating to any misuse by
                    the User of the content and services provided on the Site.
                  </li>
                  <li class="list-group-item">
                    <span class="list_img"></span> The information contained in
                    the Site has been obtained from sources believed to be
                    reliable. loginrich disclaims all warranties as to the
                    accuracy, completeness or adequacy of such information.
                  </li>
                  <li class="list-group-item">
                    <span class="list_img"></span> loginrich makes no warranty
                    that: (a) the Site will meet your requirements; (b) the Site
                    will be available on an uninterrupted, timely, secure, or
                    error-free basis; (c) the results that may be obtained from
                    the use of the Site or any services offered through the Site
                    will be accurate or reliable.
                  </li>
                  <li class="list-group-item">
                    <span class="list_img"></span> The User’s right to privacy
                    is of paramount importance to loginrich. Any information
                    provided by the User will not be shared with any third
                    party. loginrich reserves the right to use the information to
                    provide the User a more personalized online experience.
                  </li>
                  <li class="list-group-item">
                    <span class="list_img"></span> The Site provides links to
                    web sites and access to content, products and services from
                    third parties, including users, advertisers, affiliates and
                    sponsors of the Site. You agree that loginrich is not
                    responsible for the availability of, and content provided
                    on, third party web sites. The User is requested to peruse
                    the policies posted by other web sites regarding privacy and
                    other topics before use. loginrich is not responsible for
                    third party content accessible through the Site, including
                    opinions, advice, statements and advertisements, and User
                    shall bear all risks associated with the use of such
                    content. loginrich is not responsible for any loss or damage
                    of any sort User may incur from dealing with any third
                    party.
                  </li>
                  <li class="list-group-item">
                    <span class="list_img"></span> loginrich shares its bank
                    details only through an identified mail id
                    info@loginrich.com As a normal course of business loginrich
                    never authorizes its employees to share its bank details
                    with our customers directly. In case, you receive any
                    notification on addition/change/update of bank details of
                    loginrich from any other email id, please write to us at
                    info@loginrich.com.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Disclaimer;
