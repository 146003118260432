import '../component/itservices.css';
import React, { Component } from "react";
//import PropTypes from "prop-types";

export class ITServices extends Component {
 // static propTypes = {};

  render() {
    return (
      <div>

        <div className="container-fluid" >
        <section id="contact" className="bg-light py-5">
    <div className="container">
      <div className="row">
        
      <div className="col-md-12 text-center one">
    
      <h3 className="text-light rounded contactusbg">Services</h3>
          <h2 ></h2>
      </div>
        <div className="col-md-12">
          
          <p>Ready to embrace the digital transformation journey with Loginrich Technology & Managment Pvt. Ltd.? Reach out to us today to discuss your business needs and explore how we can help you achieve your goals.</p>
        </div>
       
      </div>
    </div>
  </section>
          {/* <div className="container ">
            <div className="row ">
              <div className="col-md-12 tcw  text-center">
                <h1 className="text-center fs-1" style={{'margin': '100px'}}>
                  {" "}
                  IT & Software Design, Management & Support Services
                </h1>
              </div>
            </div>
          </div> */}
        </div>

        <div className="container mt-2">
          <div className="row">
            <div className="col-md-4 flex-column">
              <div className="card  ms-1 me-1 text-center">
                <div className="text-center mt-1">
                  <img
                    src="../img/mobile-app-development.jpg"
                    width="172"
                    height="172"
                    className=""
                    alt="..."
                  />
                </div>
                <div className="card-body">
                  <h4 className="card-title fs-5 text-b servicetext2">
                    {" "}
                    Mobile App Development
                  </h4>
                  <p className="card-text">
                    {" "}
                    Transform your ideas into a mobile application through
                    Loginrich. The mobile application industry is undergoing a
                    vast transformative phase. With the advancement of
                    micro-processing technology.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 flex-column">
              <div className="card  ms-1 me-1 text-center">
                <div className="text-center mt-1">
                  <img
                    src="../img/software development.png"
                    width="172"
                    height="172"
                    className=""
                    alt="..."
                  />
                </div>
                <div className="card-body">
                  <h4 className="card-title fs-5 text-b servicetext2" >
                    {" "}
                    Software Development
                  </h4>
                  <p className="card-text">
                    {" "}
                    Promising a Compatible Partner! In Loginrich,
                    software creation and innovation are enabling companies,
                    both small and large, to achieve high levels of performance.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 flex-column">
              <div className="card  ms-1 me-1 text-center">
                <div className="text-center mt-1">
                  <img
                    src="../img/digital-media.jpg"
                    width="172"
                    height="172"
                    className=""
                    alt="..."
                  />
                </div>
                <div className="card-body">
                  <h4 className="card-title fs-5 text-b servicetext2"> Digital Media</h4>
                  <p className="card-text">
                    {" "}
                    Broaden the digital footprint of your business! How about
                    taking a step forward. Our services come under four headings
                    – Marketing Contact, Digital Presence, Sales Enablement &
                    Marketing Technology.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-md-4 flex-column">
              <div className="card  ms-1 me-1 text-center">
                <div className="text-center mt-1">
                  <img
                    src="../img/web-development.jpg"
                    width="172"
                    height="172"
                    className=""
                    alt="..."
                  />
                </div>
                <div className="card-body">
                  <h4 className="card-title fs-5 text-b servicetext2">Web Development</h4>
                  <p className="card-text">
                    {" "}
                    Our Web designers help to metamorphose your brand into a
                    visual story. We help to turn your website into an effective
                    way to engage with your audience and a high-performing
                    marketing tool for your business.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 flex-column">
              <div className="card  ms-1 me-1 text-center">
                <div className="text-center mt-1">
                  <img
                    src="../img/IT_Consultancy.jpg"
                    width="172"
                    height="172"
                    className=""
                    alt="..."
                  />
                </div>
                <div className="card-body">
                  <h4 className="card-title fs-5 text-b servicetext2"> IT Consultancy</h4>
                  <p className="card-text">
                    {" "}
                    Still handling IT on your own? You’ve got better things to
                    do than figure out the right tech support for your business.
                    Let Loginrich handle getting you the top-notch
                    solution you deserve with our network of partners and
                    products.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 flex-column">
              <div className="card  ms-1 me-1 text-center">
                <div className="text-center mt-1">
                  <img
                    src="../img/ecommarce.jpg"
                    width="172"
                    height="172"
                    className=""
                    alt="..."
                  />
                </div>
                <div className="card-body">
                  <h4 className="card-title fs-5 text-b servicetext2">
                    E-Commerce Application
                  </h4>
                  <p className="card-text">
                    As one of the best e-commerce application development
                    companies, Loginrich have built numerous on-demand
                    e-commerce applications to mobilize your business. Our
                    E-Commerce applications are highly competent to deliver
                    immediate needs of the market landscape and get aligned to
                    every process digitally.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ITServices;
