

import './App.css';
// Importing
import React from 'react';
import { BrowserRouter as Router,Routes, Route } from 'react-router-dom';
import AboutUs from './component/AboutUs';
import ContactUs from './component/ContactUs';
import Footer from './component/Footer';
import NavBar from './component/NavBar';
import Service from './component/Service';
import Home from './component/Home';
import Career from './component/Career';
import ITServices from './component/ITServices';
import Privacy from './component/Privacy';
import Disclaimer from './component/Disclaimer';
import TermsCondition from './component/TermsCondition';
import ContactUsAdmin from './component/ContactUsAdmin';
import AdminDashboard from './component/AdminDashboard';

function App() {
  return (
    <Router>
   

  <NavBar></NavBar>

<Routes> 

        <Route exact path='' element={< Home/>}></Route>
        <Route exact path='/aboutus' element={< AboutUs/>}></Route>
        <Route exact path='/service' element={< Service/>}></Route>
        <Route exact path='/contactus' element={< ContactUs/>}></Route>
        <Route exact path='/career' element={< Career/>}></Route>
        <Route exact path='/itservice' element={< ITServices/>}></Route>
        <Route exact path='/acontactus' element={<ContactUsAdmin/>}></Route>
        <Route exact path='/privacy' element={< Privacy/>}></Route>
        <Route exact path='/disclaimer' element={< Disclaimer/>}></Route>
        <Route exact path='/terms_condition' element={<TermsCondition/>}></Route>
        <Route exact path='/admin' element={<AdminDashboard/>}></Route>
        
              
      
               
                </Routes>
                 
                <Footer/>

                
</Router>

  );
}
export default App;
