
import axios from 'axios';
import {lbase_url} from "./bootapi";
import Badge from 'react-bootstrap/Badge';
import React, { Component } from 'react'
//const AddContactus=()=>{
  export class AddContactus extends Component {
  msgcolor="";
  constructor(props) {
    super(props);
    this.state = {msgcolor: "success"};
  }
  render() {
    const changeColormsg=(colorName)=>{
      this.setState ({msgcolor: colorName});
      }
    const addContact=(name,email,subject,message)=>{
             axios.post(`${lbase_url}/contactUs`, {
              ipaddress:"http://localhost:8080/contactUs",
              cname: name,
              cmail: email,
              csubject:subject,
              cmessage:message   
      })
      .then(function (response) {
       document.getElementById("dismsg").innerText = response.data.msg;
       changeColormsg('success');
       resetForm(this);
      })
      .catch(function (error) {
        console.log(error);
        document.getElementById('dismsg').innerText = error;
         changeColormsg('danger');
      });
    }

    const resetForm=()=>
    {
      document.getElementById("name").value="";
      document.getElementById("email").value="";
      document.getElementById("subject").value="";
      document.getElementById("message").value="";
    }//end of resetForm
    const handleClick=()=> {
      const name = document.getElementById("name").value;
      const email = document.getElementById("email").value;
      const subject = document.getElementById("subject").value;
      const message = document.getElementById("message").value;
      const status=isValidEmail(email);
      if(name===null || name==='')
      {
        document.getElementById("dismsg").innerText="Please Enter Name";
        this.setState({msgcolor: 'danger'});
      }
      else if(email===null || email==='' || status===false)
      {      
        document.getElementById("dismsg").innerText="Please Enter Valid Email";
        this.setState({msgcolor: 'danger'});
      }
      else if(subject===null || subject==='')
      {
        document.getElementById("dismsg").innerText="Please Enter Subject";
        this.setState({msgcolor: 'danger'});
      }
      else if(message===null || message==='')
      {
        document.getElementById("dismsg").innerText="Please Enter Message";
        this.setState({msgcolor: 'danger'});
      }
      else{
       
        addContact(name,email,subject,message);
      }
     

    }//end of function


    
function isValidEmail(email1) 
{  
  
 return /^[a-zA-Z_][\w-].*@[a-zA-Z_][\w-]+\.\w+$/.test(email1);
};
    return(
        <div>
            
  <section className="about my-1">
  <div className="container">
  <div className="text-center">

    

                  <div className="one">
                      <h4>Get In Touch</h4>
                    </div>
                 
                </div>

                <div className="row my-5">
      
                 <div className="col-sm-12 col-md-6 col-lg-6 col-12">
                      <div  className="h-75 rounded-1 mt-2 getintouch">
                          <img src="../img/contactus.png" alt=""  />
                         
                      </div>
                 </div>
                 {/*<!-- col End-->*/}

                 <div className="col-sm-12 col-md-6 col-lg-6 col-12 mt-1">
                  {/* <form> */}
                  <div className="mb-1">
                       <div className="form-group text-center">
                           
                           {/* <span className="text-danger badge " id="dismsg"></span> */}
                           <Badge  style={{
        fontFamily: '-apple-system',
        fontSize: "1.5rem",
        fontWeight: 1.5,
        lineHeight: 1.5,
        padding: "0 1em"
      }} bg={this.state.msgcolor} id="dismsg"></Badge>
                         </div>
                      </div>

                  <div className="mb-3">
                       <div className="form-group">
                           <label className=" text-start h5" for="name">Name</label>
                           <input type="text" className="form-control contact-form" name="name" id="name" placeholder="Name"/>
                       </div>
                      </div>
      
                      <div className="mb-3">
                       <div className="form-group">
                           <label className="label mb-1 h5" for="email">Email Address</label>
                           <input type="email" className="form-control contact-form " name="email" id="email" placeholder="Email"/>
                       </div>
                      </div>
      
                      <div className="mb-3">
                           <div className="form-group">
                           <label className="label mb-1 h5" for="subject">Subject</label>
                           <input type="text" className="form-control contact-form  " name="subject" id="subject" placeholder="Subject"/>
                           </div>
                      </div>
      
                      <div className="mb-3">
                       <div className="form-group">
                           <label className="label h5" for="#">Message</label>
                           <textarea name="message" className="form-control " id="message" cols="30" rows="4" placeholder="Message" ></textarea>
                       </div>
                      </div>
      
                      <div className="mb-3">
                       <div className="form-group">
                           <input type="submit" onClick={handleClick} value="Submit" className="btn contactusbg"/>
                           
                       </div>
                      </div>
                    {/* </form> */}
                    </div>
                    {/**end of col2 */}
                 </div>
                 {/**end of row */}
  </div>
  {/**end of contactus container */}
    </section>
          </div>
     );
 
}
}
 
export default AddContactus;