import '../component/career.css';
import React, { Component } from 'react'
import axios from 'axios';
import {lbase_url} from "./api/bootapi";
import Badge from 'react-bootstrap/Badge';

export class Career extends Component {
  msgcolor="";
  radioChecked_f=false;
  constructor(props) {
    super(props);
    this.state = {msgcolor: "success"};
    this.state = {
      name: 'React',
      data:["Java Full Stack Developer","Flutter Developer","Fresher","Web Developer","Android Developer"],
      selected:''
    };
  }

  
  render() {
   const handleChange = (e) => {
      this.setState({selected:e.target.value})
    }
  
    const handleClear = (e) => {
      this.setState({selected:""})
    }
 
 const resetForm=()=>
 {
   document.getElementById("fname").value="";
   document.getElementById("lname").value="";
   document.getElementById("contactNumber").value="";
   document.getElementById("emailId").value="";
   document.getElementById("state").value="";
  document.getElementById("city").value="";
  handleClear();
    this.setState({ radioChecked_f: false }); // to clear it
        this.setState({ radioChecked_m: false }); // to clear it
        this.setState({ radioChecked_o: false }); // to clear it
 }//end of resetForm
const changeColormsg=(colorName)=>{
 this.setState ({msgcolor: colorName});
 }
 const radiohandleChangeData = (e) => {
  
        if(e.currentTarget.id==="femalegender")
        {
          this.setState({
            radioChecked_m:false,
            radioChecked_o:false,
            radioChecked_f: e.target.value
          });
        }//end of if
        else  if(e.currentTarget.id==="maleGender")
        {
          this.setState({
            radioChecked_f:false,
            radioChecked_o:false,
            radioChecked_m: e.target.value
          
          });
        }//end of else if
        else
        {
          this.setState({
            radioChecked_f:false,
            radioChecked_m: false,
            radioChecked_o:e.target.value
            
          
          });
        }//end of else
      };//end of function radiohandleChangeData


const handleClick = () => {

  const fname = document.getElementById("fname").value;
  const lname = document.getElementById("lname").value;
  const contactNumber = document.getElementById("contactNumber").value;
  const emailId = document.getElementById("emailId").value;
  const statusemailId=isValidEmail(emailId);
  const state = document.getElementById("state").value;
  const city=document.getElementById("city").value;

  const gender = document.getElementsByName("inlineRadioOptions");

  //var gender_f = document.getElementsByName('femalegender').checked;
  
  
var genderv,genders=false;

  for (var i = 0; i < gender.length; i++) {
      if (gender[i].checked){
        genderv=gender[i].value;
        genders=true;
      }
       
  }
  
  const experienceType = document.getElementById("experienceType").value;
   const jobProfile=document.getElementById("jobProfile").value;
   //alert("experienceType:"+experienceType);
   //alert("jobProfile:"+jobProfile);
    if(fname===null || fname==='')
   {
     document.getElementById("msg2").innerText="Please Enter Name";
     this.setState({msgcolor: 'danger'});
   }
   else if(lname===null || lname==='')
   {
     document.getElementById("msg2").innerText="Please Enter Last Name";
     this.setState({msgcolor: 'danger'});
   }
   else if(contactNumber===null || contactNumber==='')
   {
     document.getElementById("msg2").innerText="Please Enter Contact";
     this.setState({msgcolor: 'danger'});
   }

  
   else if(emailId===null || emailId==='' || statusemailId===false)
   {      
     document.getElementById("msg2").innerText="Please Enter Valid Email";
     this.setState({msgcolor: 'danger'});
   }

   else if(state===null || state==='')
   {
     document.getElementById("msg2").innerText="Please Enter state";
     this.setState({msgcolor: 'danger'});
   }
   else if(city===null || city==='')
   {
     document.getElementById("msg2").innerText="Please Enter city";
     this.setState({msgcolor: 'danger'});
   }
   else if(gender===null || gender==='')
   {
     document.getElementById("msg2").innerText="Please Enter gender";
     this.setState({msgcolor: 'danger'});
   }
   else if(experienceType===null || experienceType==='')
   {
     document.getElementById("msg2").innerText="Please Enter experienceType";
     this.setState({msgcolor: 'danger'});
   }
   else if(jobProfile===null || jobProfile==='')
   {
     document.getElementById("msg2").innerText="Please Enter jobProfile";
     this.setState({msgcolor: 'danger'});
   }
   else if(genders===false)
   {
    document.getElementById("msg2").innerText="Please Select Gender";
     this.setState({msgcolor: 'danger'});
   }//Select Work Profile
   else if(experienceType==='Select Work Profile')
   {
    document.getElementById("msg2").innerText="Select Work Profile";
     this.setState({msgcolor: 'danger'});
   }
   else if(jobProfile==='Select Job Profile')
   {
    document.getElementById("msg2").innerText="Select Job Profile";
     this.setState({msgcolor: 'danger'});
   }
   else{
    
     addCarrer(fname,lname,contactNumber,emailId,state,city,genderv,experienceType,jobProfile);
   }
}//end of handleclick
   
const addCarrer=(fname,lname,contactNumber,emailId,state,city,gender,experienceType,jobProfile)=>{
   /// const [msg_Color, setmsg_Color] = useState("success");
  axios.post(`${lbase_url}/career`, {
    fname:fname,
    lname: lname,
    contactNumber: contactNumber,
    emailId:emailId,
    state:state,
    city:city,
    gender:gender,
    experienceType:experienceType,
    jobProfile:jobProfile
   
})
.then(function (response) {

document.getElementById("msg2").innerText = response.data.msg;

changeColormsg('success');
resetForm(this);

})
.catch(function (error) {
console.log(error);
document.getElementById('msg2').innerText = error;

changeColormsg('danger');

});
}//addCarrer

    function isValidEmail(email1) 
    {  
      
     return /^[a-zA-Z_][\w-].*@[a-zA-Z_][\w-]+\.\w+$/.test(email1);
    };//end of isValidEmail
   


    return (
      <div>
      

<div className="container mb-5 mt-5">

  {/*<!--heading start-->*/}
  <div className="one py-4 mt90">
         
          <h3 className="bg-primary text-light rounded contactusbg">Recruitment Application</h3>
          <h2 ></h2>
       </div>




  {/* <!--heading end--> */}  
  <div className="row justify-content-center p-2">
   
  <div className="col-sm-12 col-md-6 col-lg-4  text-center  border border-warning p-2" >
              		<p className="mb-4  text-danger fw-bold ">Kindly fill-up this recruitment application form to apply for job.</p>

                      <div>
                            
                         
                             <ol className="list-group list-group-numbered">
  <li className="list-group-item fw-bold navtextcolor">Full Stack Java Developer</li>
 
</ol>
                        
                      </div>

                     

                    
      </div>
      {/**end of col */}
    <div className="col-sm-12 col-md-6  col-lg-8">
      



    <form className="row g-3 justify-content-center ctext">
   
    
    <Badge  style={{
        fontFamily: '-apple-system',
        fontSize: "1.5rem",
        fontWeight: 1.5,
        lineHeight: 1.5,
        padding: "0 1em"
      }} bg={this.state.msgcolor} id="msg2"></Badge>
    

    
          <div className="col-md-5">
            <label for="fname" className="form-label">First Name</label>
            <input type="text" className="form-control" id="fname"/>
          </div>

          <div className="col-md-5">
            <label for="lname" className="form-label">Last Name</label>
            <input type="text" className="form-control" id="lname"/>
          </div>

          <div className="col-md-5">
            <label for="contactnumber" className="form-label">Contact Number</label>
            <input type="number" className="form-control" id="contactNumber"/>
          </div>

          <div className="col-md-5">
            <label for="emailid" className="form-label">Email ID</label>
            <input type="email" className="form-control" id="emailId"/>
          </div>
          <div className="col-md-5">
            <label for="state" className="form-label">State</label>
            <input type="text" className="form-control" id="state"/>
          </div>
          <div className="col-md-5">
            <label for="city" className="form-label">City</label>
            <input type="text" className="form-control" id="city"/>
          </div>
        
          <div className="col-md-5 ">
        
          {/*  */}
            <label for="gender" className="form-label g-2">Gender </label>

            <div className="form-check form-check-inline mb-0 g-2">
              <input className="form-check-input " type="radio" name="inlineRadioOptions" id="femalegender"
                value="Female" checked={this.state.radioChecked_f} onClick={radiohandleChangeData}/>
              <label className="form-check-label g-2" for="femaleGender">Female</label>
            </div>
        
            <div className="form-check form-check-inline mb-0 ">
              <input className="form-check-input" type="radio" name="inlineRadioOptions" id="maleGender"
                value="Male" checked={this.state.radioChecked_m} onClick={radiohandleChangeData}/>
              <label className="form-check-label" for="maleGender">Male</label>
            </div>
        
            <div className="form-check form-check-inline mb-0">
              <input className="form-check-input" type="radio" name="inlineRadioOptions" id="otherGender"
                value="Other" checked={this.state.radioChecked_o} onClick={radiohandleChangeData}/>
              <label className="form-check-label" for="otherGender">Other</label>
            </div>
{/*  */}



          </div>





          <div className="col-md-5">

          <select className="form-select" aria-label="Default select example" id="experienceType">
  <option selected>Select Work Profile</option>
  <option value="Fresher">Fresher</option>
  <option value="Experince">Experince</option>
  
</select>
          
          </div>
          <div className="col-md-5">


 <select className="form-control" value={this.state.selected} id="jobProfile" name="Auditee" onChange={handleChange.bind(this)}>
          <option>Select Job Profile</option>
          {this.state.data.map(data=><option>{data}</option>)}
        </select>

</div>


           <div className="col-md-5 ms-2">
       
            <input type="button" onClick={handleClick} value="Submit" className="btn contactusbg border-1"/>
                          
          </div>

        </form>

    </div>
    {/**end of col */}

  </div>
  {/**end of row */}
</div>

      </div>

    )
  }
}

export default Career
