import React from "react";
import { Container } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
const Course=({ course })=>{
return(
  <div className="text-center">
 <Card style={{ width: '18rem' }} className="text-center">
    {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
    <Card.Body>
      <Card.Title>
        {course.title}
        </Card.Title>
      <Card.Text>
         {course.description}2
      </Card.Text>
     
      <Container >
       <Button variant="danger" className="me-1">Delete</Button>
         <Button variant="warning ml-3">Update</Button>
    </Container>
    </Card.Body>
  </Card>
  </div>
   
//     <Card>
//  <CardBody>
//     <CardSubtitle className="font-weight-bold">{course.title}</CardSubtitle>
//     <CardText>{course.description}</CardText>
//     <Container className="text-center">
//         <Button color="danger">Delete</Button>
//         <Button color="warning ml-3">Update</Button>
//     </Container>
// </CardBody>    
//     </Card>
   
)
}
export default Course;