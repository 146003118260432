
import React,{useState,useEffect} from "react";
import Course from "./Course";
import axios from 'axios';
import {base_url} from "./bootapi";
    const ContactusApi=()=>{
        useEffect(() => {
            // Update the document title using the browser API
            getAllCourseFromServer();
        alert("testing");
        }, []);
        //const base_url="http://localhost:8080/get";
const getAllCourseFromServer=()=>{
// Make a request for a user with a given ID
axios.get(`${base_url}/get`)
  .then(function (response) {
    // handle success
    //console.log(response.data);
    setCourses(response.data);
  })
  .catch(function (error) {
    // handle error
    console.log(error);
  })
  .finally(function () {
    // always executed
  });
}
//end of getAllCourseFromServer
    const[courses,setCourses]=useState([
        
{title:"Java Course",description:"this is demo course"},
{title:"Django Course",description:"this is demo course"},
{title:"Reactjs Course",description:"this is demo course"}
    ])

    return(
       <div>
            <h1>All Course</h1>
           <p>List of courses are as follows</p>
            {
                courses.length>0?courses.map((item)=><Course course={item}/>)
                :"No courses"
            } 
         </div>
    );
} 
export default ContactusApi;
// // import PropTypes from 'prop-types'
// import React, { c,Component } from 'react'
// import Course from "./api/Course";
// export class ContactusApi extends Component {
//   // static propTypes = {}
//   constructor(props) {
//     super(props);
   
//     this.state = {
//       count: 0
//     };
//   }
//   componentDidMount() {
//     document.title = `You clicked ${this.state.count} times`;
//   }
//   componentDidUpdate() {
//     document.title = `You clicked ${this.state.count} times`;
//   }
  
//   render() {
//     const[courses,setCourses]=useState([
        
//       {title:"Java Course",description:"this is demo course"},
//       {title:"Django Course",description:"this is demo course"},
//       {title:"Reactjs Course",description:"this is demo course"}
//           ]);
//     return (
//       <div>
//           <h1>All Course</h1>
//             <p>List of courses are as follows</p>
//             {
//                courses.length>0?courses.map((item)=><Course course={item}/>)
//                :"No courses"
//             }

//       <p>You clicked {this.state.count} times</p>
//       <button onClick={() => this.setState({ count: this.state.count + 1 })}>
//         Click me
//       </button>
//     </div>
//     )
//   }
// }

// export default ContactusApi