import React, { Component } from 'react'
import AddContactus from './api/AddContactus';

export class ContactUsAdmin extends Component {
  

  render() {
    // const Allcountactus=()=>{
    //     useEffect(()=>{
    //         alert("testing");
    //     });
    //     const [contacts,setContacts]=useState([
    //         {title:"Java",description:"this is demo"},
    //         {title:"python",description:"this is demo"},
    //         {title:"Django",description:"this is demo"}
    //     ]);
    // };
    return (
      <div>
      <AddContactus></AddContactus>
      </div>
    )
  }
}

export default ContactUsAdmin
