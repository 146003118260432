
import React,{useEffect} from "react";


import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AdminHeader from './AdminHeader';
 import ContactusApi from './api/ContactusApi';
// import Course from './api/Course';
import AdminMenu from './AdminMenu';
///export class AdminDashboard extends Component {

  
const AdminDashboard=() =>{
  useEffect(() => {
    // Update the document title using the browser API
document.title="Home-Admin Dashboard";
}, []);

    return (
      <div className="square border border-primary">
       {/*  */}
       <AdminHeader/>
            <Container >
              
            <Row>
             <Col md={4} className="square border border-danger "><AdminMenu></AdminMenu></Col>
             {/* <Col md={8}> <Course course={{title:"java course",description:"this is begnner course."}}></Course></Col> */}
             <Col md={8} className="square border border-success "> <ContactusApi/></Col>
           </Row>
            </Container>
           
       
       </div>
    )
  }


export default AdminDashboard