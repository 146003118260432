import '../component/contactus.css';
import React, { Component } from 'react'
import AddContactus from './api/AddContactus';
export class ContactUsHome extends Component {
  static propTypes = {

  }

  render() {
    return (
      <div>


           


          <section id="contact" className=" py-0">

  <div className="container text-center mt-0 mb-0 ">
  <div className="row mb-3 contactusbg">
    <h4>Contact Us</h4>
  </div>
</div>

    <div className="container">
      <div className="row">
        <div className="col-md-12">
          
          <p>Ready to embrace the digital transformation journey with Loginrich Technology & Managment Pvt. Ltd.? Reach out to us today to discuss your business needs and explore how we can help you achieve your goals.</p>
        </div>
        
      </div>
    </div>
  </section>
<AddContactus></AddContactus>
  
{/**end of contactus first section */}

{/*<!-- Mission and Vision Section --> */}
<section id="mission-vision" className=" mt-5"> 
      <div className="container"> 
        <div className="row justify-content-between"> 
          <div className="contactcard card col-md-4  mb-5 " style={{'margin': '0px','padding': '25px'}}> 
              <i className="fa-solid fa-house"></i>
              <h3 style={{'align-self': 'center'}}>
                <i className="bi bi-bank2" style={{'font-size': '30px',' color': 'rgb(0, 0, 0)', 'opacity':' 1' }}></i> Address 
                <hr    
                  style={{'width': '250px','background-color': '#f6f4fa', 'height': '3px'}}/>
                </h3> 
                  <hr className=" m-auto" style={{'height': '3px'}}/>   
              <div>
                 <h5 className='text-warning'><i className="fas fa-home me-2 " ></i><span className="icon-ind me-2"></span>India <small><i>(Corporate Office)</i></small></h5>
                                       
                     <p><sapn className="">Srno29,Office No.5,Sawant Park,Balaji Nagar,Pune Satara Road,Pune, Maharashtra, 411043, IN</sapn></p>
                      </div>
          </div> 
          

          <div className="contactcard card col-md-3  mb-5" style={{'margin': '0px','padding': '25px'}}> 
              <i className="fa-solid fa-house"></i>
              <h3 style={{'align-self': 'center'}}>
              <i className="fas fa-envelope me-2"></i>Email
                <hr    
                  style={{'width': '250px','background-color': '#f6f4fa', 'height': '3px'}}/>
                </h3> 
                  <hr className=" m-auto" style={{'height': '3px'}}/>   
                   <div>
                                       
                   	<h5 className="text-warning"><i className="fas fa-envelope me-2"></i>Email</h5>
										<p className="mb-0"><a className="" href="mailto:info@loginrich.com">info@loginrich.com</a></p>
										<p className="mb-0"><a className="" href="mailto:career@loginrich.com">career@loginrich.com</a></p>
										<p className="mb-0"><a className="" href="mailto:support@loginrich.com">support@loginrich.com</a></p>
								    </div>
          </div> 
          



          <div className="contactcard card col-md-3 mb-5" style={{'margin': '0px','padding': '25px'}}> 
              <i className="fa-solid fa-house"></i>
              <h3 style={{'align-self': 'center'}}>
              <i className="fas fa-phone-volume me-2 "></i> <span className=''>Call</span> 
                <hr    
                  style={{'width': '250px','background-color': '#f6f4fa', 'height': '3px'}}/>
                </h3> 
                  <hr className=" m-auto" style={{'height': '3px'}}/>   
                   <div>         
                   <h5 className="text-warning"><i className="fas fa-phone-volume me-2 "></i> <span>Call</span></h5>
										<p className="mb-0"><a className="" href="tel:+919890089721">+91 98 9008 9721</a></p>
										<p className="mb-0"><a className="" href="tel:+918390691173">+91 83 9069 1173</a></p>
										  </div>
          </div> 
          

        </div> 
      </div> 
    </section> 

      </div>
    )
  }
}

export default ContactUsHome
