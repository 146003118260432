import { Link } from "react-router-dom";
import React, { Component } from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import Image from 'react-bootstrap/Image';
import styles from "../component/navbar2.css";

export class NavBar extends Component {
  static propTypes = {};

  render() {
    return (
      <div>
      
        <section>
         
<div>
<Navbar expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand href="">
        {/* src="../img/new/logoweb.png"
                  alt=""
                  width="200"
                  height="70" */}
           <Image src="../img/new/logoweb.png" width="200"
                  height="70" thumbnail className="border-0 pt-4 me-5" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse >
          <Nav className=" ms-auto fw-bold fs-6 " >
          
            <Nav.Link id={["home"]}  className="navtextalign" as={Link} to="/" ><span style={{  "color": "#800080","font-size": "1.1em"}}>Home</span></Nav.Link>
            {/* <Nav.Link  id={["home"]}  href="/aboutus" ><span >About Us</span></Nav.Link> */}
            {/* <Link to="/" id={["home"]} className="nav-link"><span >About Us</span></Link> */}
            <Nav.Link id={["home"]} className="navtextalign" as={Link} to="/aboutus" ><span style={{  "color": "#800080","font-size": "1.1em" }}>About Us</span></Nav.Link>
            <Nav.Link id={["home"]} className="navtextalign" as={Link} to='/itservice' ><span style={{  "color": "#800080","font-size": "1.1em" }}>Service</span></Nav.Link>
            
            <Nav.Link id={["home"]} className="navtextalign" as={Link} to='/career' ><span style={{  "color": "#800080","font-size": "1.1em" }}>Career</span></Nav.Link>
            <Nav.Link id={["home"]} className="navtextalign" as={Link} to="/contactus" ><span style={{  "color": "#800080","font-size": "1.1em" }}>Contact Us</span></Nav.Link>
            <Nav.Link id={["home"]} className="navtextalign" as={Link} to="/admin" ><span ></span></Nav.Link>
           {/* <Nav.Link id={["home"]}  as={Link} to="/acontactus" ><span ></span></Nav.Link>  */}
            {/* <Nav.Link class="home" id="career" href="/career">Career</Nav.Link>
            <Nav.Link className="home" href="/contactus">ContactUs</Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
</div>
     
        </section>

        {/*end of main div navbar */}












      </div>
    );
  }
}

export default NavBar;
