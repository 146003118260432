import '../component/contactus.css';
import React, { Component } from 'react'

import axios from 'axios';
import {lbase_url} from "./api/bootapi";
import Badge from 'react-bootstrap/Badge';
export class ContactUs extends Component {
  msgcolor="";
  constructor(props) {
    super(props);
    this.state = {msgcolor: "success"};
  }
  render() {
    const changeColormsg=(colorName)=>{
      this.setState ({msgcolor: colorName});
      }
    const addContact=(name,email,subject,message)=>{
             axios.post(`${lbase_url}/contactUs`, 
             {
              withCredentials: false,
              headers: {
                'Access-Control-Allow-Origin': '*',
                 'Content-Type': 'application/json',
                 'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                        }
          },
           {
              ipaddress:"http://localhost:8080/contactUs",
              cname: name,
              cmail: email,
              csubject:subject,
              cmessage:message   
      },
      
      )
      .then(function (response) {
       document.getElementById("dismsg").innerText = response.data.msg;
       changeColormsg('success');
       resetForm(this);
      })
      .catch(function (error) {
        console.log(error);
        document.getElementById('dismsg').innerText = error;
         changeColormsg('danger');
      });
    }
//     const { data } = await api.post(`post-tip`, payload, {
//       headers: {
//         "Access-Control-Allow-Origin": true
//       }
//     });

    const resetForm=()=>
    {
      document.getElementById("name").value="";
      document.getElementById("email").value="";
      document.getElementById("subject").value="";
      document.getElementById("message").value="";
    }//end of resetForm
    const handleClick=()=> {
      const name = document.getElementById("name").value;
      const email = document.getElementById("email").value;
      const subject = document.getElementById("subject").value;
      const message = document.getElementById("message").value;
      const status=isValidEmail(email);
      if(name===null || name==='')
      {
        document.getElementById("dismsg").innerText="Please Enter Name";
        this.setState({msgcolor: 'danger'});
      }
      else if(email===null || email==='' || status===false)
      {      
        document.getElementById("dismsg").innerText="Please Enter Valid Email";
        this.setState({msgcolor: 'danger'});
      }
      else if(subject===null || subject==='')
      {
        document.getElementById("dismsg").innerText="Please Enter Subject";
        this.setState({msgcolor: 'danger'});
      }
      else if(message===null || message==='')
      {
        document.getElementById("dismsg").innerText="Please Enter Message";
        this.setState({msgcolor: 'danger'});
      }
      else{
       
        addContact(name,email,subject,message);
      }
     

    }//end of function


    
function isValidEmail(email1) 
{  
  
 return /^[a-zA-Z_][\w-].*@[a-zA-Z_][\w-]+\.\w+$/.test(email1);
};




    return (
      <div>


          


          <section id="contact" className="bg-light py-5">
    <div className="container">
      <div className="row">
        
      <div className="col-md-12 text-center one">
    
      <h3 className="text-light rounded contactusbg">Contact Us</h3>
          <h2 ></h2>
      </div>
        <div className="col-md-12">
          
          <p>Ready to embrace the digital transformation journey with Loginrich Technology & Managment Pvt. Ltd.? Reach out to us today to discuss your business needs and explore how we can help you achieve your goals.</p>
        </div>
       
      </div>
    </div>
  </section>

  


  <div className="container mb-5 mt-5">
  
  <div className="row justify-content-center p-2">
  <div className="form-group text-center">
                           
                           {/* <span className="text-danger badge " id="dismsg"></span> */}
                           <Badge  style={{
        fontFamily: '-apple-system',
        fontSize: "1.5rem",
        fontWeight: 1.5,
        lineHeight: 1.5,
        padding: "0 1em"
      }} bg={this.state.msgcolor} id="dismsg"></Badge>
                         </div>

    <div className="col-sm-12 col-md-6  col-lg-8 order-md-last">
    {/* <AddContactus2></AddContactus2> */}
    {/* <form>*/}
    <div className="mb-3">
    <label for="Inputfullname" className="form-label">Full Name</label>
    <input type="text" className="form-control" name="name" id="name" aria-describedby="InputfullnameHelp"/>
  
  </div>
  <div className="mb-3">
    <label for="InputEmail1" className="form-label">Email address</label>
    <input type="email" className="form-control" name="email" id="email" aria-describedby="emailHelp"/>
    <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div>
  </div>
  <div className="mb-3">
    <label for="Inputsubject" className="form-label">Subject</label>
    <input type="text" className="form-control" name="subject" id="subject"/>
  </div>
  
  
  <div className="mb-3">
	<label className="label" for="#">Message</label>
		<textarea  className="form-control" name="message" id="message" cols="30" rows="4" placeholder="Message"></textarea>
		</div>
    <div className="mb-3">
	
  <button type="submit" onClick={handleClick} className="btn contactusbg">Submit</button>
  	</div>
                          

  {/*</form> */}

    </div>
    {/**end of col */}

    <div className="col-sm-12 col-md-6 col-lg-4  text-center  p-2 border border-dark" >
                  <h3 className='text-light rounded contactusbg'>Let's get in touch</h3>
									<p className="mb-4  text-danger fw-bold">We're open for any suggestion or just to have a chat</p>

                      <div>
                        <h5 className='text-dark'><i className="fas fa-home me-2 " ></i><span className="icon-ind me-2"></span>India <small><i>(Corporate Office)</i></small></h5>
                                       
                           <p>
                        <sapn className="navtextcolor fw-bold">Srno29,Office No.5,Sawant Park,Balaji Nagar,Pune Satara Road,Pune, Maharashtra, 411043, IN</sapn></p>
                      </div>

                      <div className='mt-2'>   
                                       
                            <h5 className="text-dark"><i className="fas fa-envelope me-2"></i>Email</h5>
                            <p className="mb-0"><a className="navtextcolor fw-bold" href="mailto:info@loginrich.com">info@loginrich.com</a></p>
                            <p className="mb-0"><a className="navtextcolor fw-bold" href="mailto:career@loginrich.com">career@loginrich.com</a></p>
                            <p className="mb-0"><a className="navtextcolor fw-bold" href="mailto:support@loginrich.com">support@loginrich.com</a></p>
                      </div>

                      <div className='mt-2 '>         
                   <h5 className="text-dark"><i className="fas fa-phone-volume me-2 "></i> <span>Call</span></h5>
										<p className="mb-0"><a className="navtextcolor fw-bold" href="tel:+919890089721">+91 98 9008 9721</a></p>
										<p className="mb-0"><a className="navtextcolor fw-bold" href="tel:+91839069173">+91 83 9069 1173</a></p>
										  </div>
      </div>
      {/**end of col */}
  </div>
  {/**end of row */}
</div>
{/**
  <section className="about my-5 my-5">
  <div className="container">
  <div className="text-center">
                  <div className="one">
                      <h1>Get In Touch</h1>
                    </div>
                 
                </div>

                <div className="row my-5">
      
                 <div className="col-sm-12 col-md-6 col-lg-6 col-12">
                      <div  className="h-75 rounded-1 mt-2 getintouch">
                          <img src="../img/get-in-touch.jpg" alt=""  />
                         
                      </div>
                 </div>*/}
                 {/*<!-- col End-->*/}
 {/*
                 <div className="col-sm-12 col-md-6 col-lg-6 col-12 mt-1">
                  <form>
                  <div className="mb-3">
                       <div className="form-group">
                           <label className=" text-start h5" for="name">Name</label>
                           <input type="text" className="form-control contact-form" name="name" id="name" placeholder="Name"/>
                       </div>
                      </div>
      
                      <div className="mb-3">
                       <div className="form-group">
                           <label className="label mb-1 h5" for="email">Email Address</label>
                           <input type="email" className="form-control contact-form " name="email" id="email" placeholder="Email"/>
                       </div>
                      </div>
      
                      <div className="mb-3">
                           <div className="form-group">
                           <label className="label mb-1 h5" for="subject">Subject</label>
                           <input type="text" className="form-control contact-form  " name="subject" id="subject" placeholder="Subject"/>
                           </div>
                      </div>
      
                      <div className="mb-3">
                       <div className="form-group">
                           <label className="label h5" for="#">Message</label>
                           <textarea name="message" className="form-control " id="message" cols="30" rows="4" placeholder="Message"></textarea>
                       </div>
                      </div>
      
                      <div className="mb-3">
                       <div className="form-group">
                           <input type="submit" value="Submit" className="btn btn-danger"/>
                           
                       </div>
                      </div>
                    </form>
                    </div>
                    {/**end of col2 */}
                {/**  </div>*/}
                 {/**end of row */}
   {/**</div>
  */}
  {/** end of contactus container */}
   {/** </section>*/}
  
{/**end of contactus first section */}

{/*<!-- Mission and Vision Section --> */}

{/*

<section id="mission-vision" className="bg-light "> 
      <div className="container"> 
        <div className="row justify-content-between"> 
          <div className="contactcard card col-md-4  mb-5 " style={{'margin': '0px','padding': '25px'}}> 
              <i className="fa-solid fa-house"></i>
              <h3 style={{'align-self': 'center'}}>
                <i className="bi bi-bank2" style={{'font-size': '30px',' color': 'rgb(0, 0, 0)', 'opacity':' 1' }}></i> Address 
                <hr    
                  style={{'width': '250px','background-color': '#f6f4fa', 'height': '3px'}}/>
                </h3> 
                  <hr className=" m-auto" style={{'height': '3px'}}/>   
              <div>
                 <h5 className='text-warning'><i className="fas fa-home me-2 " ></i><span className="icon-ind me-2"></span>India <small><i>(Corporate Office)</i></small></h5>
                                       
                     <p><sapn className="">Srno29,Office No.5,Sawant Park,Balaji Nagar,Pune Satara Road,Pune, Maharashtra, 411043, IN</sapn></p>
                      </div>
          </div> 
          

          <div className="contactcard card col-md-3  mb-5" style={{'margin': '0px','padding': '25px'}}> 
              <i className="fa-solid fa-house"></i>
              <h3 style={{'align-self': 'center'}}>
              <i className="fas fa-envelope me-2"></i>Email
                <hr    
                  style={{'width': '250px','background-color': '#f6f4fa', 'height': '3px'}}/>
                </h3> 
                  <hr className=" m-auto" style={{'height': '3px'}}/>   
                   <div>
                                       
                   	<h5 className="text-warning"><i className="fas fa-envelope me-2"></i>Email</h5>
										<p className="mb-0"><a className="" href="mailto:info@loginrich.com">info@loginrich.com</a></p>
										<p className="mb-0"><a className="" href="mailto:career@loginrich.com">career@loginrich.com</a></p>
										<p className="mb-0"><a className="" href="mailto:support@dloginrich.com">support@dloginrich.com</a></p>
								    </div>
          </div> 
          



          <div className="contactcard card col-md-3 mb-5" style={{'margin': '0px','padding': '25px'}}> 
              <i className="fa-solid fa-house"></i>
              <h3 style={{'align-self': 'center'}}>
              <i className="fas fa-phone-volume me-2 "></i> <span>Call</span> 
                <hr    
                  style={{'width': '250px','background-color': '#f6f4fa', 'height': '3px'}}/>
                </h3> 
                  <hr className=" m-auto" style={{'height': '3px'}}/>   
                   <div>         
                   <h5 className="text-warning"><i className="fas fa-phone-volume me-2 "></i> <span>Call</span></h5>
										<p className="mb-0"><a className="" href="tel:+917210043004">+91 98 9008 9721</a></p>
										<p className="mb-0"><a className="" href="tel:+917210053005">+91 83 9069 1173</a></p>
										  </div>
          </div> 
          

        </div> 
      </div> 
    </section> 
*/}
      </div>
    )
  }
}

export default ContactUs
